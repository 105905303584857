import * as React from "react";
import TexturedBackground from "../../images/Careers/2.jpg";

const CareerBenefits = () => {
  return (
    <main>
      <div class="hero relative overflow-hidden">
        <div class="max-w-7xl mx-auto">
          <div class="mt-36 mb-12 relative z-10 mx-auto text-center  ">
            <main class="mx-auto max-w-7xl">
              <div class="sm:text-center">
                <h1 class="text-4xl tracking-normal font-bold text-gray-500 sm:text-5xl md:text-4xl">
                  Current Job Openings
                </h1>
              </div>
            </main>
          </div>
        </div>
      </div>

      <div class="bg-white py-4 ">
        <div class="max-w-4xl mx-auto bg-teal-50 shadow sm: rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Digital Marketing Specialist
            </h3>

            <div class="mt-2 sm:flex sm:items-start sm:justify-between">
              <div class="max-w-xl text-sm text-gray-500">
                <p>
                  The Digital Marketing Specialist will be an integral part of
                  the digital marketing and advertising team, and will assist
                  the Digital Marketing Director in planning, creation, and
                  execution of all clients online and social marketing.
                </p>
              </div>
              <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                <a href="/careers/digital-marketing">
                  <button
                    type="button"
                    class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm "
                  >
                    View Job
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden bg-white py-4 ">
          <div class="max-w-4xl mx-auto bg-teal-50 shadow sm: rounded-lg">
            <div class="px-4 py-5 sm:p-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Executive Assistant
              </h3>

              <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                <div class="max-w-xl text-sm text-gray-500">
                  <p>
                    We are currently looking for an Executive Assistant to work
                    with the CEO of a highly valued tech start-up. Ideal
                    candidates will have a keen intuition, be proactive with
                    their thinking, and be able to juggle many competing
                    priorities at once.
                  </p>
                </div>
                <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                  <a href="/careers/executive-assistant">
                    <button
                      type="button"
                      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm "
                    >
                      View Job
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-20 mb-20">
          <a
            href="/contact-us"
            class="inline-flex px-10 text-white bg-blue-800 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-blue-900 focus:outline-none hover:text-white"
          >
            Get In Touch
          </a>
        </div>
      </div>
    </main>
  );
};

export default CareerBenefits;

/* 

Open opportunities button for hero image 

<div class="mx-auto text-center mt-14">
<a
href="/contact-us"
class="text-blue-900 bg-white font-medium rounded-lg text-xl px-20 py-4 hover:bg-green-300 focus:outline-none hover:text-green-800"
>
Open Opportunities
</a>
</div> 

*/
