import * as React from "react";
import Layout from "../../components/Layout";
import CareerBenefits from "../../components/Careers/CareerBenefits";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const careers = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career Opportunities | Digital Marketing, Web Design, Web Development</title>

        <meta
          name="description"
          content="We are rapidly growing. Check out our available career opportunities in digital marketing, web design, web development, and sales."
        />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          name="twitter:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>

      <CareerBenefits />
      
    </Layout>
  );
};

export default careers;
